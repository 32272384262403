<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card class="my-card">
            <h3 v-if="this.$i18n.locale === 'bn'"><span>কৃষি মন্ত্রণালয়</span> এর <span>ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম</h3>
            <h3 class="mb-2" v-if="this.$i18n.locale === 'en'">Welcome to <b>Integrated Digital Service Delivery Platform Service</b> of <b>Ministry of Agriculture</b></h3>
            <h4><i style="vertical-align: sub;" data-icon="i" class="icon"></i> {{ $t('externalUserIrrigation.your_all_service_here') }}</h4>
          </b-card>
        </b-col>
    </b-row>
    </b-container>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { profileShow } from '../api/routes'
import { snakeToWords } from '@/Utils/fliter'

export default {
  data () {
    return {
    }
  },
  async created () {
    const tmp = await this.getFarmerInfoData()
    if (Object.keys(tmp).length) {
      this.saveLavel(tmp)
    }
  },
  computed: {
    authUser: function () {
      return this.$store.state.Auth.authUser
    }
  },
  methods: {
    async getFarmerInfoData () {
        const params = { user_id: this.authUser.user_id, mobile_no: this.authUser.mobile_no }
        const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, profileShow, params)
        const tmpData = result.success ? result.data : {}
        return JSON.parse(JSON.stringify(tmpData))
    },
    saveLavel (data) {
      const labels = []
      Object.keys(data).forEach((key) => {
        const obj = {}
        if (key === 'region' || key === 'gender' || key === 'division_id' || key === 'district_id' || key === 'upazilla_id' || key === 'status') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Dropdown'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else if (key === 'present_address' || key === 'permanent_address') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Paragraph'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else if (key === 'dob') {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'Date'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        } else {
          const fieldName = 'field_name'
          obj[fieldName] = key
          const fieldType = 'field_type'
          obj[fieldType] = 'InputField'
          const labelName = 'label_name'
          obj[labelName] = snakeToWords(key)
          const labelValue = 'value'
          obj[labelValue] = data[key]
          labels.push(obj)
        }
      })
      this.$store.commit('ExternalLrcpn/setLrcpnUserlabel', labels)
    }
  }
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
